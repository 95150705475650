import alooma from './alooma';
import withMessageId from './withMessageId';
import withPageCategory from './withPageCategory';

if (process.env.JS_ENV_CLIENT) {
  const initEventStream = () => {
    alooma('eventstream');

    window.eventstream = withPageCategory(withMessageId(window.eventstream));
    window.alooma = window.eventstream;
  };

  initEventStream();
}
